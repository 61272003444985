<template>
  <header class="text-gray-600 body-font top-0 w-full bg-gray-100">
    <div class="flex flex-wrap p-5 flex-col md:flex-row items-center">
      <a class="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0">
        <!--<svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-10 h-10 text-white p-2 bg-blue-500 rounded-full mr-3" viewBox="0 0 24 24">
          <path d="M12 2L2 7l10 5 10-5-10-5zM2 17l10 5 10-5M2 12l10 5 10-5"></path>
        </svg>-->
        <span class="text-xl">AnBord | gemeinsam planen</span>
      </a>
      <nav class="md:mx-auto flex flex-wrap items-center text-base justify-center gap-4">
        <nuxt-link v-if="isAuthenticated && user?.role!='planner'" as="button" class="inline-flex items-center border-0 py-1 px-3 focus:outline-none hover:bg-gray-200 rounded text-base mt-4 md:mt-0" to="/overview"><UIcon name="i-material-symbols-checklist" class="w-5 h-5 mr-2" />Übersicht</nuxt-link>
        <nuxt-link v-if="isAuthenticated" as="button" class="inline-flex items-center border-0 py-1 px-3 focus:outline-none hover:bg-gray-200 rounded text-base mt-4 md:mt-0" to="/planning"><UIcon name="i-material-symbols-edit-note" class="w-5 h-5 mr-2" />Planung</nuxt-link>
        <nuxt-link v-if="isAuthenticated" as="button" class="inline-flex items-center border-0 py-1 px-3 focus:outline-none hover:bg-gray-200 rounded text-base mt-4 md:mt-0" to="/reporting"><UIcon name="i-material-symbols-bar-chart" class="w-5 h-5 mr-2" />Auswertung</nuxt-link>
        <nuxt-link v-if="isAuthenticated && user?.role!='planner'" as="button" class="inline-flex items-center border-0 py-1 px-3 focus:outline-none hover:bg-gray-200 rounded text-base mt-4 md:mt-0" to="/setup"><UIcon name="i-material-symbols-tools-wrench-outline" class="w-5 h-5 mr-2" />Einrichtung</nuxt-link>
      </nav>
      <nuxt-link v-if="isAuthenticated" as="button" class="inline-flex items-center border-0 py-1 px-3 focus:outline-none hover:bg-yellow-200 rounded text-base mt-4 md:mt-0" to="/help"><UIcon name="i-material-symbols-question-mark" class="w-5 h-5 mr-2" />Hilfe</nuxt-link>
      <nuxt-link v-if="isAuthenticated && user?.role!='planner'" as="button" class="inline-flex items-center border-0 py-1 px-3 focus:outline-none hover:bg-yellow-200 rounded text-base mt-4 md:mt-0" to="/admin"><UIcon name="i-material-symbols-tools-wrench-outline" class="w-5 h-5 mr-2" />Verwaltung</nuxt-link>
      <div class="">
        <div v-if="isAuthenticated"><nuxt-link as="button" to="/profile" class="text-blue-500 inline-flex items-center border-0 py-1 px-3 focus:outline-none hover:bg-gray-200 rounded text-base mt-4 md:mt-0"><UIcon name="i-material-symbols-person-celebrate" class="w-5 h-5 mr-2" />{{ user?.email }}</nuxt-link></div>
        <div v-else>Du bist nicht angemeldet</div>
      </div>
      <nuxt-link to="/logout" as="button"  v-if="isAuthenticated" class="inline-flex items-center bg-gray-100 border-0 py-1 px-3 focus:outline-none hover:bg-gray-200 rounded text-base mt-4 md:mt-0">Abmelden
        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5 ml-1" viewBox="0 0 24 24">
          <path d="M5 12h14M12 5l7 7-7 7"></path>
        </svg>
      </nuxt-link>
      <nuxt-link to="/login" as="button" v-if="!isAuthenticated" class="inline-flex items-center bg-gray-100 border-0 py-1 px-3 focus:outline-none hover:bg-gray-200 rounded text-base mt-4 md:mt-0">Anmelden
        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5 ml-1" viewBox="0 0 24 24">
          <path d="M5 12h14M12 5l7 7-7 7"></path>
        </svg>
      </nuxt-link>
    </div>
  </header>
</template>

<script lang="ts" setup>
const authStore = useAuthStore();
const isAuthenticated = computed(() => authStore.isAuthenticated);
const user = computed(() => authStore.user);

</script>

<style>

</style>